import {useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {styled} from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import {RootState} from '../store/store';

const DashboardLayoutRoot = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  //   height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  //   paddingTop: 64,
  marginTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256,
  },
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  height: 'calc(100vh - 64px)',
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate();
  const discordUserInfo = useSelector(
    (state: RootState) => state.user.discordUserInfo,
  );
  if (!discordUserInfo?.isLogin) {
    navigate('/login', {replace: false});
    return;
  }

  return (
    <DashboardLayoutRoot data-id="DashboardLayoutRoot">
      <DashboardNavbar
        onMobileNavOpen={() => {
          console.log('onMobileNavOpen');
          setMobileNavOpen(true);
        }}
      />
      <DashboardSidebar
        onMobileClose={() => {
          console.log('close silder');
          setMobileNavOpen(false);
        }}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper data-id="DashboardLayoutWrapper">
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
