import {createSlice, PayloadAction, createAction} from '@reduxjs/toolkit';
import {RootState} from './store';

export enum LoginUserType {
  ADMIN = 'ADMIN',
  DISCORD_USER = 'DISCORD_USER',
}

export enum IRoles {
  MANAGER = 'MANAGER',
}

const SLICE_NAME = 'user';

interface AdminUserInfo {
  username: string;
  token: string;
}

interface DiscordUserInfo {
  discord: string;
  nickname: string;
  avatar: string;
  token: string;
  isLogin: boolean;
  isCompleteInfo: boolean;
  roles: IRoles | null;
}

interface LoginUserStates {
  adminUserInfo?: AdminUserInfo;
  discordUserInfo?: DiscordUserInfo;
  loginUserType?: LoginUserType | null;
  lastLoginUserType?: LoginUserType | null;
}

const initialState: LoginUserStates = {
  adminUserInfo: {
    username: '',
    token: '',
  },
  discordUserInfo: {
    discord: null,
    nickname: null,
    avatar: null,
    token: null,
    isLogin: false,
    isCompleteInfo: false,
    roles: null,
  },
  loginUserType: null,
  lastLoginUserType: null,
};

// export const setLogout = createAction(`${SLICE_NAME}/logout`);

export const loginUserInfoSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLogout: (state, {payload}: PayloadAction<null>) => {
      state.discordUserInfo = initialState.discordUserInfo;
      return state;
    },
    setDiscordUserInfo: (state, action: PayloadAction<DiscordUserInfo>) => {
      return {...state, discordUserInfo: action.payload};
    },

    setLoginUserType: (state, action: PayloadAction<LoginUserType>) => {
      return {...state, loginUserType: action.payload};
    },
    setAdminLogin: (state, action: PayloadAction<AdminUserInfo>) => {
      return {
        ...state,
        loginUserType: LoginUserType.ADMIN,
        adminUserInfo: {
          username: action.payload.username,
          token: action.payload.token,
        },
        lastLoginUserType: LoginUserType.ADMIN,
      };
    },
    setDiscordUserLogout: (state) => {
      return {...state, discordUserInfo: undefined};
    },
    setAdminLogout: (state) => {
      return {...state, loginUserType: undefined, adminUserInfo: undefined};
    },
  },
});

export const {
  setDiscordUserInfo,
  setLoginUserType,
  setAdminLogin,
  setAdminLogout,
  setDiscordUserLogout,
  setLogout,
} = loginUserInfoSlice.actions;

export const selectDiscordUserInfo = (state: RootState) =>
  state.user.discordUserInfo;
export const selectLoginUserType = (state: RootState) =>
  state.user.loginUserType;
export const selectAdminUserinfo = (state: RootState) =>
  state.user.adminUserInfo;
export const selectLastLoginUserType = (state: RootState) =>
  state.user.lastLoginUserType;
