import {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import {useDispatch} from 'react-redux';
import {setLogout} from '../store/loginUserInfoSlice';
import Logo from './Logo';

const DashboardNavbar = ({onMobileNavOpen, ...rest}) => {
  const [notifications] = useState([]);
  const dispatch = useDispatch();
  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{flexGrow: 1}} />
        {/* <IconButton color="inherit" size="large">
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
        <IconButton
          onClick={() => {
            dispatch(setLogout());
          }}
          color="inherit"
          size="large"
          data-id="logout...."
        >
          <InputIcon />
        </IconButton>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
