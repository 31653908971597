import {Outlet} from 'react-router-dom';
import {styled} from '@material-ui/core/styles';

const MainLayoutRoot = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DefaultLayout = () => (
  <MainLayoutRoot>
    <Outlet />
  </MainLayoutRoot>
);

export default DefaultLayout;
