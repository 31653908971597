import { useEffect } from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  Typography,
  Theme,
} from '@material-ui/core';

import {makeStyles} from '@material-ui/styles';

import {
  // AlertCircle as AlertCircleIcon,
  // BarChart as BarChartIcon,
  // Lock as LockIcon,
  // Settings as SettingsIcon,
  // ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  // UserPlus as UserPlusIcon,
  // Users as UsersIcon,
  // DollarSign as DollarSignIcon,
} from 'react-feather';

// import NavItem from './NavItem';
// import NavItemSub from './NavItemSub';
import { NavItem, NavItemOptions } from './NavMenu/NavItem' // New NavItem Component

// Router
import { useNavigate } from 'react-router-dom';

// Material Icons
import { 
  MonetizationOnOutlined as CoinIcon,
  // Person as UserIcon,
  SavingsOutlined as InvestmentIcon,
  Gite as FarmIcon,
} from '@mui/icons-material';

// Redux
import { RootState } from '../store/store';
import { useSelector}  from 'react-redux';
import { IRoles } from '../store/loginUserInfoSlice';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Administrator',
  name: 'Katarina Smith',
}

const useStyle = makeStyles((theme: Theme) => {
  return {
    left1: {
      //   display: 'none',
      '@media (min-width:1200px)': {
        // display: 'block',
      },
    },
    left2: {
      display: 'none',
      '@media (min-width:1200px)': {
        display: 'block',
      },
    },
  };
});

const DashboardSidebar = ({onMobileClose, openMobile}) => {
  const location = useLocation();
  const navigate = useNavigate()
  const classes = useStyle();
  const discordUserInfo = useSelector(
    (state: RootState) => state.user.discordUserInfo,
  );

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const menuItems: NavItemOptions = discordUserInfo?.roles?.includes(IRoles.MANAGER) ? 
    [
      {
        title: 'My Profile',
        Icon: UserIcon,
        subMenuOptions: [
          {
            title: 'Coin',
            Icon: CoinIcon,
            onClick: () => navigate('/app/profile/coin'),
          },
          {
            title: 'Investment',
            Icon: InvestmentIcon,
            onClick: () => navigate('/app/profile/invest'),
          },
        ],
      },
      {
        title: 'Farm Information',
        Icon: FarmIcon,
        subMenuOptions: [
          {
            title: 'Coin',
            Icon: CoinIcon,
            onClick: () => navigate('/app/farmInfo/coin'),
          },
          {
            title: 'Investment',
            Icon: InvestmentIcon,
            onClick: () => navigate('/app/farmInfo/invest'),
          },
        ],
      },
    ]
    :
    [
      {
        title: 'My Profile',
        Icon: UserIcon,
        subMenuOptions: [
          {
            title: 'Coin',
            Icon: CoinIcon,
            onClick: () => navigate('/app/profile/coin'),
          },
          {
            title: 'Investment',
            Icon: InvestmentIcon,
            onClick: () => navigate('/app/profile/invest'),
          },
        ],
      },
    ]

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Avatar
          data-id="pppppp"
          // component={RouterLink}
          src={discordUserInfo.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64,
          }}
          // to="/app/profile/coin"
        />
        <Typography color="textPrimary" variant="h5">
          {discordUserInfo.nickname}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {discordUserInfo.discord}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{p: 2}}>
        <List>
          {menuItems.map((item) => (
            <NavItem
              key={item.title}
              title={item.title}
              Icon={item.Icon}
              subMenuOptions={item.subMenuOptions}
              onClick={item.onClick}
            />
          ))}
        </List>
        {/* <List>
          <NavItemSub />
        </List> */}
      </Box>
      <Box sx={{flexGrow: 1}} />
    </Box>
  );

  return (
    <>
      <div className={classes.left1}>
        {/* lgUp mobile */}
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </div>
      <div className={classes.left2}>
        {/* xlDown mobile */}
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </div>
    </>
  );
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
