import { useState } from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import {
  Icon,
  User as UserIcon,
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
} from 'react-feather';
import CurrencyPound from '../../icons/CurrencyPound';
import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core/styles';

const useStyle = makeStyles((theme: Theme) => {
  return {
    text: {
      '& span': {
        fontSize: 14,
      },
    },
    icon: {
      minWidth: 'initial',
      marginRight: '8px',
    },
    list_item_button: {
      padding: '10px 8px',
      borderRadius: '4px',
    },
    sublist_item_button: {},
  };
})

export type NavItemOptions = NavItemProps[]

interface NavItemProps {
  // href?: string
  title: string
  Icon: Icon
  subMenuOptions?: SubMenuItemOptions[]
  onClick?: () => void
}

interface SubMenuItemOptions extends NavItemProps {
  // href?: string
  title: string
  Icon: Icon
  onClick?: () => void
}

export const NavItem: React.FC<NavItemProps> = ({ Icon, title, subMenuOptions, onClick, ...rest }) => {
  const [open, setOpen] = useState(true);
  const classes = useStyle();
  const handleClick = () => {
    setOpen(!open)
    onClick && onClick()
  }
  return (
    <>
      <ListItemButton
        className={classes.list_item_button}
        onClick={handleClick}
        disableGutters
        {...rest}
      >
        <ListItemIcon className={classes.icon}>
          {/* <UserIcon /> */}
          <Icon />
        </ListItemIcon>
        <ListItemText className={classes.text} primary={title} />
        {subMenuOptions?.length > 0 ? open ? <ChevronUpIcon /> : <ChevronDownIcon /> : null}
      </ListItemButton>

      {subMenuOptions?.length > 0 &&
        <Collapse in={open} timeout="auto" unmountOnExit>
          {subMenuOptions.map((option) => (
            <List component="div" disablePadding key={option.title}>
              <ListItemButton 
                onClick={option.onClick}
                className={classes.sublist_item_button}
              >
                <ListItemIcon 
                  className={classes.icon}
                >
                  {/* <CurrencyPound /> */}
                  <option.Icon />
                </ListItemIcon>
                <ListItemText className={classes.text} primary={option.title} />
              </ListItemButton>
            </List>
          ))}
        </Collapse>
      }
      
      {/* <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton className={classes.sublist_item_button}>
            <ListItemIcon className={classes.icon}>
              <CurrencyPound />
            </ListItemIcon>
            <ListItemText className={classes.text} primary="My Coin" />
          </ListItemButton>
        </List>
      </Collapse> */}
      
    </>
  );
}
